import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "gt-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_dropdown_select = _resolveComponent("tm-dropdown-select")!
  const _component_top_filter = _resolveComponent("top-filter")!
  const _component_compose_template_main = _resolveComponent("compose-template-main")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "composeInsertTemplate",
    "modal-title": _ctx.title,
    size: "xLarge",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_top_filter, { class: "mb-4" }, {
        "filter-line-left-default": _withCtx(() => [
          _createVNode(_component_top_filter_button, {
            icon: "add",
            text: "New template",
            onClick: _ctx.openModalCreateTemplate
          }, null, 8, ["onClick"]),
          _createVNode(_component_top_filter_button, {
            text: _ctx.expanded ? 'Collapse' : 'Expand',
            icon: _ctx.expanded ? 'unfold_less' : 'unfold_more',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = !_ctx.expanded))
          }, null, 8, ["text", "icon"])
        ]),
        "filter-line-right-prepend": _withCtx(() => [
          _createVNode(_component_tm_dropdown_select, {
            modelValue: _ctx.category,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.category) = $event)),
            options: _ctx.categoryOptions
          }, {
            "selected-item": _withCtx(({ opt }) => [
              _createVNode(_component_tm_button, { size: "large" }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_icon, {
                    name: "filter_alt",
                    size: "small",
                    left: !_ctx.isSmMax
                  }, null, 8, ["left"]),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(opt), 1),
                  _createVNode(_component_tm_icon, {
                    name: "tmi-arrow-bottom",
                    size: "small",
                    right: "",
                    class: "gt-sm"
                  })
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["modelValue", "options"])
        ]),
        _: 1
      }),
      _createVNode(_component_compose_template_main, { expanded: _ctx.expanded }, null, 8, ["expanded"])
    ]),
    _: 1
  }, 8, ["modal-title"]))
}