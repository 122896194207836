
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import ComposeTemplateMain from '@/components/pages/compose/ComposeTemplateMain.vue'
import useComposeOpenModal from '@/compositions/compose/useComposeOpenModal'
import { categoryOptions } from '@/definitions/_general/_data/optionsList'
import TmDropdownSelect from '@/components/shared/dropdowns/TmDropdownSelect.vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'ComposeInsertTemplateModal',
  components: { TmDropdownSelect, ComposeTemplateMain, TmButton, TmModal, TopFilter, TopFilterButton },
  props: {
    title: {
      type: String,
      default: 'Templates',
    },
  },
  setup() {
    const { isSmMax } = useBreakpoints()
    const { openModalCreateTemplate } = useComposeOpenModal()
    const tagVal = ref('')
    const category = ref(categoryOptions[0])
    const expanded = ref(false)

    return {
      openModalCreateTemplate,
      tagVal,
      category,
      isSmMax,
      categoryOptions,
      expanded,
    }
  },
})
