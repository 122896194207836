
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTemplate from '@/components/shared/TmTemplate.vue'

export default defineComponent({
  name: 'ComposeTemplateRow',
  components: { TmTemplate, TmButton },
  props: {
    name: {
      type: String,
    },
    content: {
      type: String,
    },
    expanded: {
      type: Boolean,
    },
  },
})
