
import { defineComponent } from 'vue'
import ComposeTemplateRow from '@/components/pages/compose/ComposeTemplateRow.vue'

export default defineComponent({
  name: 'ComposeTemplateMain',
  components: { ComposeTemplateRow },
  props: {
    expanded: {
      type: Boolean,
    },
  },
  setup() {
    const items = [
      {
        name: 'Appointment reminder 2',
        content: 'A quick reminder of your appointment at 11:15am on June 8th at ABC Dentists.\nIf you {First name} please call us on 01615555555 or reply to this text.',
      },
      {
        name: 'Property promo',
        content: 'A lovely 4BR home has just become available on Line Road in Egham for £350k.\nContact {Last name} today for a viewing - call on 01615555555 or reply to this text.',
      },
      {
        name: 'Customer alert',
        content: 'Your car is ready to be picked up at Joe’s Auto Repair. Thank you for choosing us for your annual service.',
      },
      {
        name: 'Callback request 2',
        content: 'Hi First name, it’s Charles from Textmagic\nEdward Tark has asked me to give you a quick call.\nPlease can you let me know when you are free to talk and I’ll phone you?\nMany thanks.',
      },
      {
        name: 'Message taken',
        content: 'Message for: First name Last name\nCaller: {First name} {Last name}\nCaller’s number: 01615555555\nMessage received on: 11:15am, 27/03/2017\nMessage: Mr Last name called to arrange a meeting, can you call him back?',
      },
    ]

    return {
      items,
    }
  },
})
